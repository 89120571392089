exports.onInitialClientRender = () => {
  console.log('hello there :)');
  console.log(
    'this CV is built with gatsby and started from https://www.gatsbyjs.org/starters/haysclark/gatsby-starter-casper/,',
  );
  console.log(
    'the design will adapt to larger screens, printers and smaller screens like phones.',
  );
  console.log(
    'The according repository is closed source at the moment but if you ask nicely I might give you access.',
  );
};
